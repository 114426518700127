import * as React from "react"
import {graphql, Link} from "gatsby"
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';

import Layout from "../components/layout"
import SEO from "../components/seo"

const FederalTaxForms = ({data}) => {
  const forms = data.allFederaltaxforms2021Csv.nodes;
  const columns = [
    {
      cell: row => <a href={"/federal-tax-form/" + row['Form_Name'].split(/\s/)[1]}>{row['Form_Name']}</a>,
      name: 'Form Code',
      selector: 'Form_Name',
      sortable: true,
      width: "170px",
    },
    {
      cell: row => <a href={"/federal-tax-form/" + row['Form_Name'].split(/\s/)[1]}>{row['Description']}</a>,

      name: 'Form Description',
      selector: 'Description',
      sortable: true,
    },
    {
      cell: row => <a href={"/federal-tax-form/" + row['Form_Name'].split(/\s/)[1]}><Button variant="secondary">Download/Print</Button></a>,
      name: 'Download/Print',
      selector: 'IRS_Link',
      width: "170px",
      button: true,
      right: true,
    }
  ];

  const currentDate = new Date().getFullYear();


  return (
    <Layout>
      <SEO 
      title={"IRS Federal Tax Forms " + currentDate} 
      description={"Download " + currentDate + " tax forms, including: Form 1024, Form 1040-C, Form 1098-E, Form 1098-T, Form 1099-INT, Form 2438, Form 5498, Form 8831... "} />
      <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <div className="breadcrumb">
                  <div className="breadcrumb-item"><Link to="/">Home</Link></div>
                  <div className="breadcrumb-item active" aria-current="page">IRS Federal Tax Forms</div>
                </div>
              </nav>
            </div>
          </div>
      </div>
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-8">
            <h1 className="hero-heading">IRS Federal Tax Forms</h1>
            <DataTable
              noHeader={true}
              columns={columns}
              data={forms}
              defaultSortField={'Form_Name'}
              pagination={true}
              paginationPerPage={25}
              overflowY={true}
            />


          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Link to="/">Go back to the homepage</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default FederalTaxForms


export const pageQuery = graphql`
  query {
    allFederaltaxforms2021Csv {
      nodes {
        Description
        Form_Name
        IRS_Details
        IRS_Link
        Special_Notes
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
